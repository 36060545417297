import axios from 'axios';

/**
 * @module ConfigService
 * @description The ConfigService singleton holds environment specific configuration returned from
 * the server so that the admin portal can be agnostic about which environment it is running in.
 *
 * To add new config values there are three steps:
 *
 * 1. Add the development value of the new config key to the env/ui-settings.json file in
 *    jumpcloud-workstation repo.
 * 2. Add the staging and production values for the new config key to the ui-settings repo, update
 *    the corresponding unit tests, and update the prod pointer: `git push origin master:prd`
 * 3. Add the new config key name to the keys array here in this file.
 *
 * @see {@link https://github.com/TheJumpCloud/jumpcloud-admin-portal/blob/master/client/util/InitializationHandler.js#L139}
 * @see {@link https://github.com/TheJumpCloud/jumpcloud-workstation/pull/351}
 * @see {@link https://github.com/TheJumpCloud/ui-formula/pull/70}
 */
export default {
  // Define the expected keys that will be used from the ui-settings response.
  uiSettings: [
    'ddAppId',
    'ddClientId',
    'ddSampleRate',
    'deviceCertUrl',
    'launchDarklyBaseUrls',
    'launchDarklyClientSideId',
    'launchDarklySource',
    'jcStatusUrl',
    'jcGoAuthority',
    'jcGoClient',
    'ssoRedirectUrl',
    'oauthRedirectUrl',
  ],

  /**
   * Fetch the ui-settings.json file and set each potential ui-setting as a property on the
   * singleton to reference the corresponding field in the ui-settings.json file, returning
   * undefined if not found.
   */
  async initialize() {
    const { data } = await axios.get('/ui-settings.json');

    // For each of the expected keys, define a new property on the ConfigService singleton that
    // returns the corresponding value from the ui-settings response or undefined if not present.
    this.uiSettings.forEach((uiSetting) => {
      Object.defineProperty(this, uiSetting, {
        configurable: true,
        enumerable: true,
        get() {
          return data[uiSetting] || undefined;
        },
      });
    });
  },

  /**
   * Remove each of the ui-settings added to the singleton when initialize() is called.
   */
  reset() {
    this.uiSettings.forEach((uiSetting) => delete this[uiSetting]);
  },
};
